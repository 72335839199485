import './App.css';
import Wizard from "../Wizard/Wizard";

function App() {
  return (
    <div className="App">
      <Wizard />
    </div>
  );
}

export default App;
