
import axios from "axios";

import { GET_DELIVERY_LOCATOINS } from "../types/deliveryLocationTypes";
import { hostname } from "../../config";

const HOSTNAME = hostname()

export const getLocations = () => {
  return async dispatch => {
    const res = await axios.get(`${HOSTNAME}/api/deliverylist`);
    let deliveryLocations = res.data.map(loc => {
      return {text: loc[0], value: loc[0]};
    });
    return dispatch({
      type: GET_DELIVERY_LOCATOINS,
      deliveryLocations
    });
  };
};