export const TYPES = {
    TEXT: "text",
    TEXTAREA: "textarea",
    DATE: "date",
    DATETIME: "datetime-local",
    NUMBER: "number",
    EMAIL: "email",
    PASSWORD: "password",
    PHONE: "tel",
    URL: "url",
    DROPDOWN: "dropdown"
}

export const ACTIONS = {
    ONBLUR: "blur",
    ONCHANGE: "change",
    ONCLICK: "click"
}