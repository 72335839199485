
import axios from "axios";

import { SAVE_PROSPECT, GET_PROSPECT_BY_ID, UPDATE_PROSPECT, RESET_SUBMIT } from "../types/prospectTypes";
import { hostname } from "../../config";

const HOSTNAME = hostname()

export const saveProspect = (data) => {
  return async (dispatch) => {
    await axios.post(`${HOSTNAME}/api/new`, data);
    return dispatch({
      type: SAVE_PROSPECT,
    });
  };
};

export const getProspectById = (id) => {
  return async dispatch => {
    const res = await axios.get(`${HOSTNAME}/api/prospect/${id}`);
    return dispatch({
      type: GET_PROSPECT_BY_ID,
      prospect: res.data
    });
    
  };
};

export const updateProspect = (data) => {
  return async (dispatch, getState) => {
    let res = await axios.post(`${HOSTNAME}/api/followup`, data);
    let prospects = getState().prospects
    return dispatch({
      type: UPDATE_PROSPECT,
      prospect: {...prospects,  row: res.data.row},
    });
  };
};

export const checkUUID = (uuid) => {
    axios.post(`${HOSTNAME}/api/uuid`, uuid).then((res) => {
      return res.data;
    });    
}

export const emailRep = (repEmailData, prospectData) => {
  let data = { repEmailData, prospectData };

  axios.post(`${HOSTNAME}/api/sendmail`, data).then((res) => {
    return res.data;  
  });

}
