import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import rootReducer from './store/reducers/index';
import './index.css';
import App from './components/App/App';
import Thanks from './components/Thanks/Thanks';
import FollowUp from './components/FollowUp/FollowUp';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';

let devTools = process.env.NODE_ENV == "development" ? composeWithDevTools : () => {}
let reduxLogger = ""//logger

const store = createStore(
  rootReducer,
  compose(
  applyMiddleware(thunk),
  //devTools()
  )
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/prospect/:id" exact={true} element={<FollowUp />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
