import { GET_DELIVERY_LOCATOINS  } from '../types/deliveryLocationTypes';

const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERY_LOCATOINS:
      return action.deliveryLocations
    default:
      return state;
  }
};

export default reducer;