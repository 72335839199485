
import axios from "axios";

import { GET_REPS } from "../types/repTypes";
import { hostname } from "../../config";

const HOSTNAME = hostname()

export const getReps = () => {
  return async dispatch => {
    const res = await axios.get(`${HOSTNAME}/api/replist`/* 'https://localhost:3001/api/replist' */);
    let reps = res.data;
    console.log("reps: ", reps )
    return dispatch({
      type: GET_REPS,
      reps
    });
    
  };
};