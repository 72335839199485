import {v4 as uuidv4} from 'uuid';

import { checkUUID } from '../../store/actions/prospectAction';

export const generateUUID = () => {
  let uuidExists = true;
    let uuid;
    let count = 0
    while (uuidExists) {
      count++
      uuid = uuidv4();
      uuidExists = checkUUID(uuid);
    }
    return {uuid, count};
}