import React, {useEffect} from "react";

import styles from "./Input.module.css";

const DropdownInput = (props) => {
  const { options, defaultValue, onChange, value, ...rest } = props;

  return (
    <React.Fragment>
      <select className={styles.example} onChange={onChange} value={value} {...rest} >
        {defaultValue && <option disabled>{defaultValue}</option>}
				{options.map((option, index) => {
          return <option value={option.value} key={`option_${index}`}>{option.text}</option>
				})}
      </select>
    </React.Fragment>
  );
} 

export default DropdownInput;