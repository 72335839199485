import React from "react";
import PropTypes from "prop-types";

import DropdownInput from "./DropdownInput";
import StandardInput from "./StandardInput";
import TextareaInput from "./TextareaInput";

import styles from "./Input.module.css";
import * as CONSTANTS from "./constants";

const Input = (props) => {

  const { label, input, ...rest } = props;
  const { text, style } = label;
  const { name, type, onChange, onClick, onBlur } = input;

  const handleActions = (e) => {
    let inputValue = e.target.value;
    let action = e.type;
    let data = {
      name,
      type,
      value: inputValue
    }
    switch(action) {
      case CONSTANTS.ACTIONS.ONBLUR:
        onBlur && onBlur(e, data);
        break;
      case CONSTANTS.ACTIONS.ONCHANGE:
        onChange && onChange(e, data);
        break;
      case CONSTANTS.ACTIONS.ONCLICK:
        onClick && onClick(e, data);
        break;
    }
  }

  const actions = {
    onBlur: handleActions,
    onChange: handleActions,
    onClick: handleActions,
  }

  const inputTypeSelector = () => {
    switch(type) {
      case CONSTANTS.TYPES.TEXT:
      case CONSTANTS.TYPES.PASSWORD:
      case CONSTANTS.TYPES.EMAIL:
      case CONSTANTS.TYPES.PHONE:
      case CONSTANTS.TYPES.URL:
        return <StandardInput {...input} {...rest} {...actions} />
      case CONSTANTS.TYPES.DROPDOWN:
        return <DropdownInput {...input} {...rest} {...actions} />
      case CONSTANTS.TYPES.TEXTAREA:
        return <TextareaInput {...input} {...rest} {...actions} />
      default:
        return <StandardInput {...input} {...rest} {...actions} />
    }
  }

  return (
    <div className={styles.input}>
      {text ? <label htmlFor={name} style={style}>{text}</label> : null}
      { inputTypeSelector() }
    </div>
  );
}

const TYPES_ARRAY = Object.values(CONSTANTS.TYPES);

Input.propTypes = {
  label: PropTypes.shape({
    text: PropTypes.string,
    style: PropTypes.object
  }),
  input: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.oneOf(TYPES_ARRAY)
  })
}

export default Input;