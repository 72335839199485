import React from "react";
import { Container, Grid } from "semantic-ui-react";

import * as CONSTANTS from "./constants";
import styles from "./ContentController.module.css";

const ContentController = (props) => {
  const { children, activeStep } = props;

  return (
    <Container className={styles.wrapper}>
      <Grid className={styles.container} columns="equal">
        <Grid.Row>
          <Grid.Column>
            {children.map((child, index) => {
              return index === activeStep ? child : null;
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export const CONTENT_CONTROLLER_CONSTANTS = CONSTANTS;

export default ContentController;
