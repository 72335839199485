import React from "react";

import styles from "./Input.module.css";

const TextareaInput = (props) => {

  const {} = props;

  return (
    <React.Fragment>
      <textarea {...props} className={`${styles.textarea}`} />
    </React.Fragment>
  );
} 

export default TextareaInput;