import React, { useState, useEffect } from "react";
import { Grid, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from 'react-redux';

import { saveStep } from "../../store/actions/wizardActions";
import { getLocations } from "../../store/actions/deliveryLocationActions";

import Input from "../common/Input/Input";
import CategoryBox from "../common/CategoryBox/CategoryBox";

import styles from "./Delivery.module.css";

const Delivery = (props) => {
  const { validator } = props;

  const dispatch = useDispatch();
  let locations = useSelector(state => state.deliveryLocations);
  let step_5 = useSelector(state => state.steps.step_5);

  const [deliveryLocation, setDeliveryLocation] = useState("Select a location...");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryState, setDeliveryState] = useState("");
  const [deliveryZip, setDeliveryZip] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryDelay, setDeliveryDelay] = useState(false);
  const [questions, setQuestions] = useState("");

  useEffect(() => {
    dispatch(getLocations());
    step_5 && setDeliveryLocation(step_5.deliveryLocation);
    step_5 && setDeliveryAddress(step_5.deliveryAddress);
    step_5 && setDeliveryCity(step_5.deliveryCity);
    step_5 && setDeliveryState(step_5.deliveryState);
    step_5 && setDeliveryZip(step_5.deliveryZip);
    step_5 && setDeliveryDate(step_5.deliveryDate);
    step_5 && setDeliveryDelay(step_5.deliveryDelay);
    step_5 && setQuestions(step_5.questions);
  }, []);

  useEffect(() => {
    dispatch(saveStep('step_5', { deliveryLocation, deliveryAddress, deliveryCity, deliveryDate, deliveryDelay, deliveryState, deliveryZip, questions }));

    switch (true) {
      case deliveryLocation != "Select a location..." && deliveryLocation != "Other"  && !deliveryDelay:
      case deliveryLocation != "Select a location..." && deliveryLocation != "Other"  && !!deliveryDelay && !!deliveryDate:
      case deliveryLocation == "Other" && !!deliveryAddress && !!deliveryCity && !!deliveryState && !!deliveryZip  && !!deliveryDelay && !!deliveryDate:
      case deliveryLocation == "Other" && !!deliveryAddress && !!deliveryCity && !!deliveryState && !!deliveryZip  && !deliveryDelay:
        console.log("step 5 valid")
        validator(true);
        break;
      case deliveryLocation == "Select a location...":
        console.log("step 5 invalid")
        validator(false);
        break;
      default:
        console.log("step 5 invalid")
        validator(false);
    }
  }, [deliveryLocation, deliveryAddress, deliveryCity, deliveryDate, deliveryDelay, deliveryState, deliveryZip, questions])

  const handleDeliveryAddress = (e, { name, value }) => {
    switch (name) {
      case "deliveryAddress":
        setDeliveryAddress(value);
        break;
      case "deliveryCity":
        setDeliveryCity(value);
        break;
      case "deliveryState":
        setDeliveryState(value);
        break;
      case "deliveryZip":
        setDeliveryZip(value);
        break;
      case "deliveryDate":
        setDeliveryDate(value);
        break;
    }
  }

  const handleQuestions = (e, { name, value }) => {
    setQuestions(value)
  }

  const deliveryLocationOnChange = (e, { value }) => {
    setDeliveryLocation(value)
  }

  const handleDelayToggle = (e, { value }) => {
    setDeliveryDelay(!deliveryDelay)
    if (!deliveryDelay) {
      setDeliveryDate("");
    }
  }

  return (
    <div className={styles.Delivery}>
      <Grid columns="equal">
        <CategoryBox name="map pin">
          <Grid columns="equal">
            <Grid.Row className={styles.no_pad}>
              <Grid.Column style={{ paddingTop: "10px" }}>
                <span style={{ color: "red"}}>* </span>Please select the delivery location:
              </Grid.Column>
              <Grid.Column style={{ paddingTop: "5px" }} width={7}>
                <Input
                  input={{
                    className: "",
                    id: "deliveryLocation",
                    name: "deliveryLocation",
                    type: "dropdown",
                    size: "mini",
                    options: locations,
                    defaultValue: "Select a location...",
                    value: deliveryLocation,
                    onChange: deliveryLocationOnChange
                  }}
                  label={{
                    style: null,
                    text: null,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            {deliveryLocation == "Other" && (
              <>
                <Grid.Row>
                  <Grid.Column className={`grid_column`}>
                    <label htmlFor={"deliveryAddress"}><span style={{ color: "red"}}>* </span>Address</label>
                    <Input
                      input={{
                        className: styles.input,
                        id: "deliveryAddress",
                        name: "deliveryAddress",
                        type: "text",
                        size: "mini",
                        value: deliveryAddress,
                        onChange: handleDeliveryAddress
                      }}
                      label={{
                        style: null,
                        text: null,
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column className={`grid_column`}>
                    <label htmlFor={"deliveryCity"}><span style={{ color: "red"}}>* </span>City</label>
                    <Input
                      input={{
                        className: styles.input,
                        id: "deliveryCity",
                        name: "deliveryCity",
                        type: "text",
                        size: "mini",
                        value: deliveryCity,
                        onChange: handleDeliveryAddress
                      }}
                      label={{
                        style: null,
                        text: null,
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className={`grid_column`}>
                    <label htmlFor={"deliveryState"}><span style={{ color: "red"}}>* </span>State</label>
                    <Input
                      input={{
                        className: styles.input,
                        id: "deliveryState",
                        name: "deliveryState",
                        type: "text",
                        size: "mini",
                        value: deliveryState,
                        onChange: handleDeliveryAddress
                      }}
                      label={{
                        style: null,
                        text: null,
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column className={`grid_column`}>
                    <label htmlFor={"deliveryZip"}><span style={{ color: "red"}}>* </span>Zip</label>
                    <Input
                      input={{
                        className: styles.input,
                        id: "deliveryZip",
                        name: "deliveryZip",
                        type: "text",
                        size: "mini",
                        value: deliveryZip,
                        onChange: handleDeliveryAddress
                      }}
                      label={{
                        style: null,
                        text: null,
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </CategoryBox>
        <CategoryBox name="clock outline">
          <Grid>
            <Grid.Row className={styles.no_pad}>
              <Grid.Column>
                <Grid columns="equal">
                  <Grid.Row className={styles.no_pad}>
                    <Grid.Column className={`${styles.toggle}`} width={10} style={{ paddingTop: "10px" }}>
                      Do you want to delay delivery for more than a month?
                    </Grid.Column>
                    <Grid.Column
                      floated="right"
                      className={`${styles.toggle}`}
                      width={3}
                    >
                      <div>No &nbsp;</div>
                      <Radio
                        toggle
                        checked={deliveryDelay}
                        onChange={handleDelayToggle}
                      />
                      <div>&nbsp; Yes</div>
                    </Grid.Column>
                  </Grid.Row>
                  {
                    !!deliveryDelay && (
                      <Grid.Row>
                    <Grid.Column style={{ paddingTop: "10px" }}>
                    <span style={{ color: "red"}}>* </span>Target delivery date:
                    </Grid.Column>
                    <Grid.Column>
                      <Input
                        input={{
                          className: styles.input,
                          id: "deliveryDate",
                          name: "deliveryDate",
                          type: "date",
                          size: "mini",
                          value: deliveryDate,
                          onChange: handleDeliveryAddress
                        }}
                        label={{
                          style: null,
                          text: null,
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                    )
                  }
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </CategoryBox>
        <CategoryBox name="question">
          <Grid.Row>
            <Grid.Column>
              Any Other Questions?
              <Input
                input={{
                  className: styles.input,
                  id: "questions",
                  name: "questions",
                  rows: "3",
                  type: "textarea",
                  value: questions,
                  onChange: handleQuestions,
                  style: { width: "100%", maxWidth: "100%", maxHeight: "100px", border: "solid 1px rgba(0,0,0,0.2)", borderRadius: "3px" }
                }}
                label={{
                  style: null,
                  text: null,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </CategoryBox>
      </Grid>
    </div>
  );
};

export default Delivery;
