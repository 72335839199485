import { SAVE_PROSPECT, GET_PROSPECT_BY_ID, UPDATE_PROSPECT, RESET_SUBMIT } from '../types/prospectTypes';

const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PROSPECT:
      return state;
    case GET_PROSPECT_BY_ID:
      return action.prospect;
    case UPDATE_PROSPECT:
      return action.prospect;
    default:
        return state;
  }
};

export default reducer;