import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { saveProspect, emailRep } from "../../store/actions/prospectAction";

import styles from "./Wizard.module.css";
import { generateUUID } from "./wizardHelper";

const WizardButtons = (props) => {
  const { activeStep, stepControl, lastStep, resetValidator, stepIsValid } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let state = useSelector(state => state);

  const handleOnClick = (e, { name }) => {
    resetValidator();
    stepControl(name);
  };

  const handleSubmit = async () => {
    let steps = state.steps;
    const d = new Date();
    let { uuid } = generateUUID();
    const dobObj = new Date(steps.step_3.dob);
    
    let repDataObj = {
      repEmail: steps.step_1.repSelected,
      loanHelp: steps.step_1.loanHelpToggle,
      financialFormCompleted: steps.step_1.financialFilledOutToggle,
      trade: {
        tradeIn: steps.step_2.tradeIn,
        tradeInFormCompleted: steps.step_2.formAlready,
        color: steps.step_2.color,
        vin: steps.step_2.vin,
        mileage: steps.step_2.mileage,
      },
      delivery: {
        deliveryAddress: steps.step_5.deliveryAddress,
        deliveryCity: steps.step_5.deliveryCity,
        deliveryDate: steps.step_5.deliveryDate,
        deliveryDelay: steps.step_5.deliveryDelay,
        deliveryLocation: steps.step_5.deliveryLocation,
        deliveryState: steps.step_5.deliveryState,
        deliveryZip: steps.step_5.deliveryZip,
      }
    }

    let prospectObj = {
      ID: uuid,
      firstName: steps.step_3.firstName,
      middleName: steps.step_3.middleName,
      lastName: steps.step_3.lastName,
      co_firstName: steps.step_3.co_firstName,
      co_middleName: steps.step_3.co_middleName,
      co_lastName: steps.step_3.co_lastName,
      Address: `${steps.step_3.address1}`,
      "Address 2": `${steps.step_3.address2}`,
      City: steps.step_3.city,
      State: steps.step_3.state,
      Zip: steps.step_3.zip,
      Date: `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`,
      "Cell Phone": steps.step_3.cellPhone,
      "Rep Name": steps.step_1.repSelected,
      Email: steps.step_3.email,
      DOB: steps.step_3.dob,
      "DOB-Mon": dobObj.getUTCMonth() + 1,
      "DOB-Day": dobObj.getUTCDate(),
      "DOB-Year": dobObj.getUTCFullYear(),
      "Delivery Location": steps.step_5.deliveryLocation,
      "Deliver Address": `${steps.step_5.deliveryAddress} ${steps.step_5.deliveryCity} ${steps.step_5.deliveryState}${steps.step_5.deliveryLocation == "Other" ? "," : ""} ${steps.step_5.deliveryZip}`,
      "Delay Delivery": steps.step_5.deliveryDelay,
      "Target Delivery Date": steps.step_5.deliveryDate,
      "Deposit Form Complete": steps.step_1.financialFilledOutToggle,
      "Trade-In": steps.step_2.tradeIn,
      "Trade-In Form Filled Out": steps.step_2.formAlready,
      "Trade-In Mileage": steps.step_2.mileage,
      "Financing Help": steps.step_1.loanHelpToggle,
      "Additional Questions": steps.step_5.questions,
      "Vin or Stock #": "",
      "Sales Price": "",
      "Trade Price": "",
      "Delivery Quote": "",
      "Upgrades": "",
      "Timeline Commitment": "",
    }

    dispatch(saveProspect(Object.values(prospectObj)));
    emailRep(repDataObj, prospectObj);

    navigate("/thanks")
  }

  return (
    <Grid style={{position: "absolute", bottom: "15px"}}>
      <Grid.Row>
        <Grid.Column>
          <Button
            name="next"
            className={styles.wizard_button}
            onClick={activeStep === lastStep ? handleSubmit : handleOnClick}
            disabled={!stepIsValid}
          >
            {activeStep === lastStep ? "Submit" : "Next"}
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingBottom: "0"}}>
        <Grid.Column>
          <Button
            name="back"
            className={styles.wizard_button}
            onClick={handleOnClick}
            disabled={activeStep === 0}
          >
            Back
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WizardButtons;
