import React, { useEffect, useState } from "react";
import { Grid, Input, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from 'react-redux';

import { saveStep } from "../../store/actions/wizardActions";
import CategoryBox from "../common/CategoryBox/CategoryBox";
import * as CONSTANTS from "./constants";
import styles from "./PersonalInfo.module.css";

const PersonalInfo = (props) => {
  const { validator } = props;
  
  const dispatch = useDispatch();

  const step_3 = useSelector(state => state.steps.step_3);

  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    co_firstName: "",
    co_middleName: "",
    co_lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
    email: "",
    dob: "",
  });

  const [titleToggle, setTitleToggle] = useState(false);

  useEffect(() => {
    step_3 && setPersonalInfo({ ...step_3 });
    step_3 && setTitleToggle({ ...step_3.titleToggle });
  }, []);

  useEffect(() => {
    let output = CONSTANTS.VALIDATION.map((val) => {
      let key = Object.keys(val)[0];
      switch (key) {
        case "middleName":
        case "address2":
          return true;
      }

      if (key == "phone") {
        return (!!personalInfo["homePhone"] && personalInfo["homePhone"] != "" && personalInfo["homePhone"] != undefined) ||
          (!!personalInfo["workPhone"] && personalInfo["workPhone"] != "" && personalInfo["workPhone"] != undefined) ||
          (!!personalInfo["cellPhone"] && personalInfo["cellPhone"] != "" && personalInfo["cellPhone"] != undefined)
      } else {
        return !!personalInfo[key] && personalInfo[key] != "" && personalInfo[key] != undefined;
      }
    })

    validator(output.indexOf(false) == -1);
  }, [personalInfo]);

  const handleTitleToggle = () => {
    setTitleToggle(!titleToggle)
    if (titleToggle) {
      setPersonalInfo({...personalInfo, co_firstName: "", co_middleName: "", co_lastName: ""})
      dispatch(saveStep('step_3', {...personalInfo, co_firstName: "", co_middleName: "", co_lastName: ""}));
    }
  }

  const handleOnChange = (e, { name, value }) => {
    setPersonalInfo({ ...personalInfo, [name]: value });
    dispatch(saveStep('step_3', { ...personalInfo, [name]: value }));
  }

  return (
    <React.Fragment>
      <Grid columns="equal">
        {CONSTANTS.CATEGORIES.map((category, index) => {
          return (
            <CategoryBox name={category.name} key={`category_${index}`}>
              <Grid columns="equal">
                {category.inputs && category.inputs.map((input, index) => {
                  return (
                    <>
                    { category.name == 'car' && 
                      <Grid.Row className={styles.no_bottom_pad}>
                        <Grid.Column className={"grid_column"} width={10}>
                            Do you want to have an additional name (co-owner) on the title?
                          </Grid.Column>
                          <Grid.Column
                            floated="right"
                            className={`${styles.toggle}`}
                            width={3}
                          >
                            <div>No &nbsp;</div>
                            <Radio
                              toggle
                              checked={titleToggle}
                              onChange={handleTitleToggle}
                            />
                            <div>&nbsp; Yes</div>
                          </Grid.Column>
                      </Grid.Row>
                    }
                    {
                      category.name == 'car' ? (
                        <Grid.Row key={`item_row_${index}`} className={`${!titleToggle ? styles.no_bottom_pad : ""}`} >
                          {input.row && input.row.map(item => {
                            if (titleToggle) {
                              return (
                                <Grid.Column className={"grid_column"} key={`item_${item.id}`}>
                                  <label htmlFor={item.htmlFor}>{item.required && <span style={{ color: "red"}}>* </span>}{item.text}</label>
                                  <Input
                                    className={`${styles.input}`}
                                    id={item.id}
                                    name={item.name}
                                    type={item.type || "text"}
                                    size="mini"
                                    onChange={handleOnChange}
                                    value={personalInfo[item.name]}
                                    disabled={category.name == "car" && !titleToggle}
                                  />
                                </Grid.Column>
                              );
                            }
                          })}
                        </Grid.Row>
                      ) : (
                        <Grid.Row key={`item_row_${index}`}>
                          {input.row && input.row.map(item => {
                            return (
                              <Grid.Column className={"grid_column"} key={`item_${item.id}`}>
                                <label htmlFor={item.htmlFor}>{item.required && <span style={{ color: "red"}}>* </span>}{item.text}</label>
                                <Input
                                  className={`${styles.input} ${category.name == "car" && !titleToggle ? styles.disabled : ""}`}
                                  id={item.id}
                                  name={item.name}
                                  type={item.type || "text"}
                                  size="mini"
                                  onChange={handleOnChange}
                                  value={personalInfo[item.name]}
                                  disabled={category.name == "car" && !titleToggle}
                                />
                              </Grid.Column>
                            );
                          })}
                        </Grid.Row>
                      )
                    }
                    </>
                  )
                })}
              </Grid>
            </CategoryBox>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default PersonalInfo;
