import React, { useEffect } from "react";

import CategoryBox from "../common/CategoryBox/CategoryBox";

import styles from "./WarrantyInfo.module.css";

const WarrantyInfo = (props) => {
  const { validator } = props;

  useEffect(() => {
    validator(true);
  }, [])

  return (
    <CategoryBox name="lock">
      <p>Now that you’ve purchased your car … it’s important to consider the long-term protection of that asset! At MATS.org, we do our best to make sure that every car that we sell is as good as possible - but we can’t know what’s going to happen in the future unfortunately!</p>
      <p>Extended service plans are the best way to mitigate those risks. Some things to consider as you decide if an extended service plan are for you:</p>
      <ul>
        <li>
          <strong>Cars are complicated.</strong> They have over 30,000 parts!
        </li>
        <li>
          <strong>Unexpected expenses can add significant stress to your life.</strong> Cars are very costly to repair.
        </li>
        <li>
          In most cases, one costly repair over 4+ years will more than pay for the cost of the plan.
        </li>
        <li>
          In many cases the service plan can be added to your loan for a small addition to the monthly payment.
        </li>
        <li>
          Our service plans can be used at ANY licensed serviced facility anywhere in the US!
        </li>
        <li>
          We have different levels and options to fit your needs, giving you the best option available for your money.
        </li>
      </ul>

      <p>Your car buying specialist will be following up with a quote on the option we feel is right for you!</p>
    </CategoryBox>
  );
}

export default WarrantyInfo;