import React from "react";
import { Icon, Grid } from "semantic-ui-react";

import styles from "./CategoryBox.module.css";

const CategoryBox = (props) => {
  const { children, name } = props;

  return (
    <Grid.Row className={styles.category_row}>
      <Grid.Column>
        <Icon name={name} className={styles.badge_icon} />
        <div className={styles.category_box}>{children}</div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default CategoryBox;
