export const CATEGORIES = [
  {
    name: "user",
    inputs: [
      {
        row: [
          {
            htmlFor: "firstName",
            id: "firstName",
            name: "firstName",
            text: "First Name",
            required: true
          },
          {
            htmlFor: "middleName",
            id: "middleName",
            name: "middleName",
            text: "Middle Name",
            required: true
          },
          {
            htmlFor: "lastName",
            id: "lastName",
            name: "lastName",
            text: "Last Name",
            required: true
          },
        ],
      },
    ],
  },
  {
    name: "car",
    inputs: [
      {
        row: [
          {
            htmlFor: "co_firstName",
            id: "co_firstName",
            name: "co_firstName",
            text: "First Name",
          },
          {
            htmlFor: "co_middleName",
            id: "co_middleName",
            name: "co_middleName",
            text: "Middle Name",
          },
          {
            htmlFor: "co_lastName",
            id: "co_lastName",
            name: "co_lastName",
            text: "Last Name",
          },
        ],
      },
    ],
  },
  {
    name: "home",
    inputs: [
      {
        row: [
          {
            htmlFor: "address1",
            id: "address1",
            name: "address1",
            text: "Address 1",
            required: true
          },
          {
            htmlFor: "address2",
            id: "address2",
            name: "address2",
            text: "Address 2",
          },
        ],
      },
      {
        row: [
          {
            htmlFor: "city",
            id: "city",
            name: "city",
            text: "City",
            required: true
          },
          {
            htmlFor: "state",
            id: "state",
            name: "state",
            text: "State",
            required: true
          },
          {
            htmlFor: "zip",
            id: "zip",
            name: "zip",
            text: "Zip",
            required: true
          },
        ],
      },
    ],
  },
  {
    name: "phone",
    inputs: [
      {
        row: [
          {
            htmlFor: "cellPhone",
            id: "cellPhone",
            name: "cellPhone",
            text: "Cell Phone",
            required: true
          },
          {
            htmlFor: "email",
            id: "email",
            name: "email",
            text: "Email",
            required: true
          },
          {
            htmlFor: "dob",
            id: "dob",
            name: "dob",
            text: "Date of Birth",
            type: "date",
            required: true
          },
        ]
      }
    ]
  },
];

export const VALIDATION = [
  {firstName: true },
  {middleName: false },
  {lastName: true },
  {address1: true },
  {address2: false },
  {city: true },
  {state: true },
  {zip: true },
  {phone: true },
  {email: true },
  {dob: true },
]

export const INPUT_KEYS = [
  "firstName",
  "middleName",
  "lastName",
  "address1",
  "address2",
  "city",
  "state",
  "zip",
  "homePhone",
  "workPhone",
  "cellPhone",
  "email",
  "dob",
]