import React from "react";

import styles from "./Input.module.css";

const StandardInput = (props) => {

  const {} = props;

  return (
    <React.Fragment>
      <input {...props} />
    </React.Fragment>
  );
} 

export default StandardInput;