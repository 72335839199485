import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import Input from "../common/Input/Input";
import { getProspectById, updateProspect } from "../../store/actions/prospectAction";

import styles from "./FollowUp.module.css";
import { validate } from "nhtsa";

const FollowUp = (props) => {
  let dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getProspectById(id));
  }, []);

  const headers = useSelector(state => state.prospects.header);
  const row = useSelector(state => state.prospects.row);
  const title = useSelector(state => state.prospects.title);
  const rowIndex = useSelector(state => state.prospects.rowIndex);

  const [rowObj, setRowObj] = useState({});
  
  useEffect(() => {
    let rowObj = {}
    if (!!headers) {
      for (let i = 0; i < headers.length; i++) {
        rowObj[headers[i]] = row[i] != undefined ? row[i] : "";
      }
    }

    setVinOrStock(rowObj['Vin or Stock #']);
    setSalesPrice(rowObj['Sales Price']);
    setTradePrice(rowObj['Trade Price']);
    setDeliveryQuote(rowObj['Delivery Quote']);
    setUpgrades(rowObj['Upgrades']);
    setTimeline(rowObj['Timeline Commitment']);

    setRowObj(rowObj);

  }, [headers])

  const [vinOrStock, setVinOrStock] = useState(rowObj['Vin or Stock #']);
  const [salesPrice, setSalesPrice] = useState(rowObj['Sales Price']);
  const [tradePrice, setTradePrice] = useState(rowObj['Trade Price']);
  const [deliveryQuote, setDeliveryQuote] = useState(rowObj['Delivery Quote']);
  const [upgrades, setUpgrades] = useState(rowObj['Upgrades']);
  const [timeline, setTimeline] = useState(rowObj['Timeline Commitment']);

  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
      setMounted(true);

      return () => { setMounted(false); };
  }, []);


  useEffect(() => {
    if (mounted) {
      validate();
    }
  }, [vinOrStock, salesPrice, tradePrice, deliveryQuote, upgrades, timeline])

  const validate = () => {
    console.log(rowObj)
    let incomingData = [
      rowObj['Vin or Stock #'],
      rowObj['Sales Price'],
      rowObj['Trade Price'],
      rowObj['Delivery Quote'],
      rowObj['Upgrades'],
      rowObj['Timeline Commitment']
    ];

    let currentData = [vinOrStock, salesPrice, tradePrice, deliveryQuote, upgrades, timeline];

    console.log(incomingData)
    console.log(currentData)

    if (JSON.stringify(incomingData) !== JSON.stringify(currentData)) {
      setDisableUpdateButton(false);
    }

    if (JSON.stringify(incomingData) == JSON.stringify(currentData)) {
      setDisableUpdateButton(true);
    }
  }

  const handleInputChange = (e, { name, value }) => {
    switch (name) {
      case "vinOrStock":
        setVinOrStock(value);
        break;
      case "salesPrice":
        setSalesPrice(value);
        break;
      case "tradePrice":
        setTradePrice(value);
        break;
      case "deliveryQuote":
        setDeliveryQuote(value);
        break;
      case "upgrades":
        setUpgrades(value);
        break;
      case "timeline":
        setTimeline(value);
        break;
    }
  }

  const handleOnClick = () => {
    dispatch(updateProspect(
      {
        data: [vinOrStock, salesPrice, tradePrice, deliveryQuote, upgrades, timeline],
        rowId: rowIndex,
        title: title[0]
      }
    )).then(res => {
      dispatch(getProspectById(id));
    })
    setDisableUpdateButton(true);
  }

  return (
    <React.Fragment>
      <Container style={{ paddingTop: "10px" }}>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              {
                headers && headers.map((header, index) => {
                  if (index % 4 == 0) {
                    return <div className={`${styles.info}`}><strong>{headers[index]}:</strong> <span>{row[index]}</span></div>
                  }
                })
              }
            </Grid.Column>
            <Grid.Column>
              {
                headers && headers.map((header, index) => {
                  if (index % 4 == 1) {
                    return <div className={`${styles.info}`}><strong>{headers[index]}:</strong> <span>{row[index]}</span></div>
                  }
                })
              }
            </Grid.Column>
            <Grid.Column>
              {
                headers && headers.map((header, index) => {
                  if (index % 4 == 2) {
                    return <div className={`${styles.info}`}><strong>{headers[index]}:</strong> <span>{row[index]}</span></div>
                  }
                })
              }
            </Grid.Column>
            <Grid.Column>
              {
                headers && headers.map((header, index) => {
                  if (index % 4 == 3) {
                    return <div className={`${styles.info}`}><strong>{headers[index]}:</strong> <span>{row[index]}</span></div>
                  }
                })
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "vinOrStock",
                  name: "vinOrStock",
                  type: "text",
                  size: "mini",
                  value: vinOrStock,
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Last 6 of vin or Stock#",
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "salesPrice",
                  name: "salesPrice",
                  type: "text",
                  size: "mini",
                  value: salesPrice,
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Sales Price",
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "tradePrice",
                  name: "tradePrice",
                  type: "text",
                  size: "mini",
                  value: tradePrice,
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Trade Price",
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "deliveryQuote",
                  name: "deliveryQuote",
                  type: "text",
                  size: "mini",
                  value: deliveryQuote,
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Delivery Quote",
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "upgrades",
                  name: "upgrades",
                  type: "textarea",
                  size: "mini",
                  value: upgrades,
                  rows: "8",
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Upgrades / Notes For Recon",
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                input={{
                  className: "",
                  id: "timeline",
                  name: "timeline",
                  type: "textarea",
                  size: "mini",
                  value: timeline,
                  rows: "8",
                  onChange: handleInputChange
                }}
                label={{
                  style: null,
                  text: "Timeline commitment made to customer?",
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button basic color='orange' onClick={handleOnClick} disabled={disableUpdateButton}>UPDATE</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default FollowUp;