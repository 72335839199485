import { SAVE_STEP } from '../types/wizardTypes';

const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_STEP:
      return {...state, [action.step]: action.payload }
    default:
      return state;
  }
};

export default reducer;