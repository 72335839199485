import React from "react";
import { Container } from "semantic-ui-react";

import styles from "./Thanks.module.css";

const Thanks = (props) => {

  const {} = props;

  return (
    <React.Fragment>
      <Container className={styles.example} >
        <h1>Thanks! We'll reach out to you shortly.</h1>
      </Container>
    </React.Fragment>
  );
} 

export default Thanks;