import React, { useState } from "react";
import { Grid, Step } from "semantic-ui-react";
import { useSelector } from "react-redux"

/* CONTENT COMPONENTS */
import InitialInfo from "../InitialInfo/InitialInfo";
import TradeIn from "../TradeIn/TradeIn";
import PersonalInfo from "../PersonalInfo/PersonalInfo";
import WarrantyInfo from "../WarrantyInfo/WarrantyInfo";
import Delivery from "../Delivery/Delivery";

/* HELPER COMPONENTS */
import ContentController, {
  CONTENT_CONTROLLER_CONSTANTS,
} from "../ContentController/ContentController";
import WizardButtons from "./WizardButtons";
import styles from "./Wizard.module.css";

const Wizard = () => {

  const step_1 = useSelector(state => state.steps.step_1);

  const [activeStep, setActiveStep] = useState(0);
  const [stepIsValid, setStepIsValid] = useState(false);

  const stepControl = (direction) => {
    direction === CONTENT_CONTROLLER_CONSTANTS.DIRECTION.BACK &&
      activeStep !== 0 &&
      setActiveStep(activeStep - 1);
    direction === CONTENT_CONTROLLER_CONSTANTS.DIRECTION.NEXT &&
      activeStep !== 4 &&
      setActiveStep(activeStep + 1);
  };

  const validator = (isValid) => {
    setStepIsValid(isValid);
  }

  const resetValidator = () => {
    setStepIsValid(false);
  }
  
  const handleStepClick = (x) => {
    setActiveStep(x)
  }

  const saveStepInfo = (x) => {
    console.log(x)
  }

  const steps = [
    {
      key: 0,
      title: "Initial Information",
      active: activeStep === 0,
      onClick: activeStep > 0 ? () => {handleStepClick(0)} : null,
      style: { cursor: "default", color: "black !important" },
      disabled: activeStep < 0,
    },
    {
      key: 1,
      title: "Trade In",
      active: activeStep === 1,
      onClick: activeStep > 1 ? () => {handleStepClick(1)} : null,
      style: { cursor: `${activeStep > 1 ? "pointer" : "arrow"}` },
      disabled: activeStep < 1,
    },
    {
      key: 2,
      title: "Personal Information",
      active: activeStep === 2,
      onClick: activeStep > 2 ? () => {handleStepClick(2)} : null,
      style: { cursor: `${activeStep > 2 ? "pointer" : "arrow"}` },
      disabled: activeStep < 2,
    },
    {
      key: 3,
      title: "Extended Service Plan",
      active: activeStep === 3,
      onClick: activeStep > 3 ? () => {handleStepClick(3)} : null,
      style: { cursor: `${activeStep > 3 ? "pointer" : "arrow"}` },
      disabled: activeStep < 3,
    },
    {
      key: 4,
      title: "Delivery",
      active: activeStep === 4,
      onClick: activeStep > 4 ? () => {handleStepClick(4)} : null,
      style: { cursor: `${activeStep > 4 ? "pointer" : "arrow"}` },
      disabled: activeStep < 4,
    },
  ];

  let stepProps = {
    validator,
    saveStepInfo
  }

  return (
    <div>
      <Grid columns="equal" className={styles.wizard_wrapper}>
        <Grid.Row>
          <Grid.Column>
            <Step.Group
              items={steps}
              ordered
              widths={5}
              fluid
              className={styles.wizard_container}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.row}>
          <Grid.Column width={3}>
            <div className={styles.wizard_button_wrapper}>
              <h2>{steps[activeStep].title}</h2>
              <h4 style={{ color: "red", textAlign: "center" }}>* required fields</h4>
              {activeStep === 2 && <p style={{ textAlign: "justify"  }}><strong>NOTE:</strong> Your Name (First/Middle/Last) should be EXACTLY as it appears on your driver's license.</p>}
              <WizardButtons
                activeStep={activeStep}
                stepControl={stepControl}
                lastStep={4}
                stepIsValid={stepIsValid}
                resetValidator={resetValidator}
              />
            </div>
          </Grid.Column>
          <Grid.Column className={styles.content}>
            <ContentController activeStep={activeStep}>
              <InitialInfo {...stepProps}/>
              <TradeIn {...stepProps} />
              <PersonalInfo {...stepProps} />
              <WarrantyInfo {...stepProps} />
              <Delivery {...stepProps} />
            </ContentController>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Wizard;
