import { GET_REPS } from '../types/repTypes';

const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPS:
      return action.reps
    default:
      return state;
  }
};

export default reducer;