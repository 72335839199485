import React, { useState, useEffect } from "react";
import { Grid, Radio, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from 'react-redux';

import { saveStep } from "../../store/actions/wizardActions";
import CategoryBox from "../common/CategoryBox/CategoryBox";
import * as CONSTANTS from "./constants";
import styles from "./TradeIn.module.css";

const TradeIn = (props) => {
  const { validator } = props;

  const dispatch = useDispatch();
  let step_2 = useSelector(state => state.steps.step_2);

  const [tradeIn, setTradeIn] = useState(step_2 && step_2.tradeIn || false);
  const [formAlready, setFormAlready] = useState(step_2 && step_2.formAlready || false);
  const [mileage, setMileage] = useState(step_2 && step_2.mileage);

  useEffect(() => {
    step_2 && setTradeIn(step_2.tradeIn)
    step_2 && setFormAlready(step_2.formAlready)
    step_2 && setMileage(step_2.mileage)
  }, []);

  useEffect(() => {
    dispatch(saveStep('step_2', { tradeIn, formAlready, mileage }));
    switch (true) {
      case !tradeIn:
      case !tradeIn && formAlready:
      case tradeIn && formAlready && !!mileage:
        validator(true);
        break;
      case tradeIn:
      case tradeIn && !formAlready:
      case tradeIn && formAlready && !mileage:
        validator(false);
        break;
      default:
        validator(false);

    }
  }, [tradeIn, formAlready, mileage]);

  const handleTradeIn = () => {
    if (tradeIn) {
      setFormAlready(false);
      setMileage(step_2.mileage);
    }
    setTradeIn(!tradeIn);
  }

  const handleFormAlready = () => {
    setFormAlready(!formAlready);
  }

  const handleOnChange = (e, {name, value}) => {
    setMileage(value)
  }

  return (
    <React.Fragment>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <CategoryBox name="car">
              <Grid columns="equal">
                <Grid.Row className={styles.no_pad}>
                  <Grid.Column>
                    <Grid columns="equal">
                      <Grid.Row className={styles.no_pad}>
                        <Grid.Column className={`${styles.toggle}`} width={10}>
                          Do you have a trade-in?
                        </Grid.Column>
                        <Grid.Column
                          floated="right"
                          className={`${styles.toggle}`}
                          width={3}
                        >
                          <div>No &nbsp;</div>
                          <Radio
                            toggle
                            checked={tradeIn}
                            onChange={handleTradeIn}
                          />
                          <div>&nbsp; Yes</div>
                        </Grid.Column>
                      </Grid.Row>
                      {tradeIn && (
                        <Grid.Row className={styles.no_pad}>
                          <Grid.Column className={`${styles.toggle}`} width={10}>
                          <span style={{ color: "red"}}>* </span>Have you already filled out the trade-in form?
                          </Grid.Column>
                          <Grid.Column
                            floated="right"
                            className={`${styles.toggle}`}
                            width={3}
                          >
                            <div>No &nbsp;</div>
                            <Radio
                              toggle
                              checked={formAlready}
                              onChange={handleFormAlready}
                            />
                            <div>&nbsp; Yes</div>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {formAlready && tradeIn && (
                        <Grid.Row>
                          <Grid.Column>
                          Since it may have been a bit since you filled out the form, what is the current <strong>EXACT</strong>mileage on your trade-in:
                          </Grid.Column>
                          <Grid.Column width={2}>&nbsp;</Grid.Column>
                          <Grid.Column width={4}>
                            <Input
                              className={styles.input}
                              id={"test"}
                              name={"test"}
                              type={"text"}
                              size="mini"
                              onChange={handleOnChange}
                              value={mileage}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {
                        !formAlready && tradeIn && (
                          <Grid.Row>
                            <Grid.Column>
                              Head on over to <a href="https://mats.org/trade/" target="_blank">https://mats.org/trade/ </a> and fill out the form. When you finish, close the Trade-in form and return here. Toggle the last question to yes to continue the process.
                            </Grid.Column>
                          </Grid.Row>
                        )
                      }
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CategoryBox>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

export default TradeIn;