import React, { useState, useEffect } from "react";
import { Grid, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from 'react-redux';

import { getReps } from "../../store/actions/repActions";
import { saveStep } from "../../store/actions/wizardActions";

import Input from "../common/Input/Input";
import CategoryBox from "../common/CategoryBox/CategoryBox";

import styles from "./InitialInfo.module.css";

const InitialInfo = (props) => {
  const { validator } = props;

  const dispatch = useDispatch();
  let reps = useSelector(state => state.reps);
  let step_1 = useSelector(state => state.steps.step_1);

  const [repList, setRepList] = useState(reps);
  const [repSelected, setRepSelected] = useState("Select Rep...");
  const [depositComplete, setDepositComplete] = useState(step_1 && step_1.depositComplete || false);
  const [loanHelpToggle, setLoanHelpToggle] = useState(step_1 && step_1.loanHelpToggle || false);
  const [financialFilledOutToggle, setFinancialFilledOutToggle] = useState(step_1 && step_1.financialFilledOutToggle || false);

  useEffect(() => {
    dispatch(getReps());
    step_1 && setRepSelected(step_1.repSelected)
    step_1 && setDepositComplete(step_1.depositComplete)
    step_1 && setLoanHelpToggle(step_1.loanHelpToggle)
    step_1 && setFinancialFilledOutToggle(step_1.financialFilledOutToggle)
  }, []);

  useEffect(() => {
    let repArray = reps.map(rep => {
      return { value: rep[1], text: rep[0] }
    })
    setRepList(repArray);
  }, [reps]);

  useEffect(() => {
    dispatch(saveStep('step_1', { repSelected, depositComplete, loanHelpToggle, financialFilledOutToggle }));
    switch (true) {
      case repSelected != "Select Rep..." && !loanHelpToggle && !!depositComplete && !financialFilledOutToggle:
      case repSelected != "Select Rep..." && loanHelpToggle && financialFilledOutToggle:
        validator(true);
        break;
      default:
        validator(false);
    }
  }, [repSelected, loanHelpToggle, financialFilledOutToggle, depositComplete])

  const handleRepListChange = (e, { value }) => {
    setRepSelected(value);
  }

  const handleDepositToggle = () => {
    setDepositComplete(!depositComplete);
  }

  const handleLoanHelpToggle = () => {
    if (loanHelpToggle) {
      setFinancialFilledOutToggle(false);
    }
    setLoanHelpToggle(!loanHelpToggle);
  };
  const handleFinancialFilledOutToggle = () => {
    setFinancialFilledOutToggle(!financialFilledOutToggle);
  };

  return (
    <div className={styles.initialInfo}>
      <Grid columns="equal">
        <CategoryBox name="info">
          <Grid columns="equal">
            <Grid.Row className={styles.no_pad}>
              <Grid.Column style={{ paddingTop: "10px" }}>
              <span style={{ color: "red"}}>* </span>Please select the MATS.org representative who is assisting you:
              </Grid.Column>
              <Grid.Column style={{ paddingTop: "5px" }} width={7}>
                <Input
                  input={{
                    className: "",
                    id: "reps",
                    name: "reps",
                    type: "dropdown",
                    size: "mini",
                    options: repList,
                    defaultValue: "Select Rep...",
                    value: repSelected,
                    onChange: handleRepListChange
                  }}
                  label={{
                    style: null,
                    text: null,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </CategoryBox>
        <CategoryBox name="money">
          <Grid>
            <Grid.Row className={styles.no_pad}>
              <Grid.Column>
                <Grid columns="equal">
                  <Grid.Row className={styles.no_pad}>
                    <Grid.Column className={`${styles.toggle}`} width={10}>
                    <span style={{ color: "red"}}>* </span>Have you already completed the deposit form?
                    </Grid.Column>
                    <Grid.Column
                      floated="right"
                      className={`${styles.toggle}`}
                      width={3}
                    >
                      <div>No &nbsp;</div>
                      <Radio
                        toggle
                        checked={depositComplete}
                        onChange={handleDepositToggle}
                      />
                      <div>&nbsp; Yes</div>
                    </Grid.Column>
                  </Grid.Row>
                  { !depositComplete &&
                    <Grid.Row>
                      <Grid.Column>
                        Click the link <a href="https://mats.org/deposit/" target="_blank">https://mats.org/deposit/ </a>
                        and fill out the form. When you finish, close the deposit form and return here. Toggle the last question to yes to continue the process.
                      </Grid.Column>
                    </Grid.Row>
                  }
                  <Grid.Row className={styles.no_pad}>
                    <Grid.Column className={`${styles.toggle}`} width={10}>
                      Would you like MATS.org to help with your loan?
                    </Grid.Column>
                    <Grid.Column
                      floated="right"
                      className={`${styles.toggle}`}
                      width={3}
                    >
                      <div>No &nbsp;</div>
                      <Radio
                        toggle
                        checked={loanHelpToggle}
                        onChange={handleLoanHelpToggle}
                      />
                      <div>&nbsp; Yes</div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            {loanHelpToggle ? (
              <>
                <Grid.Row className={styles.no_pad}>
                  <Grid.Column className={`${styles.toggle}`} width={10}>
                  <span style={{ color: "red"}}>* </span>Have you already completed financing application?
                  </Grid.Column>
                  <Grid.Column
                    floated="right"
                    className={`${styles.toggle}`}
                    width={3}
                  >
                    <div>No &nbsp;</div>
                    <Radio
                      toggle
                      checked={financialFilledOutToggle}
                      onChange={handleFinancialFilledOutToggle}
                    />
                    <div>&nbsp; Yes</div>
                  </Grid.Column>
                </Grid.Row>
                {!financialFilledOutToggle ? (
                  <Grid.Row className={styles.no_pad}>
                    <Grid.Column className={`${styles.toggle}`}>
                      Click the link <a href="https://mats.org/financing/" target="_blank">https://mats.org/financing/ </a>
                      and fill out the form. When you finish, close the financing form and return here. Toggle the last question to yes to continue the process.
                    </Grid.Column>
                  </Grid.Row>
                ) : null}
              </>
            ) : null}
          </Grid>
        </CategoryBox>
      </Grid>
    </div>
  );
};

export default InitialInfo;
